<template>
  <el-dialog
    :title="isEdit === true ? '编辑' : '新增'"
    :visible.sync="show"
    width="700px"
    center
  >
    <el-form
      v-if="show"
      :model="form"
      :label-width="formLabelWidth"
      label-position="left"
      ref="form"
      :rules="rules"
      v-loading="loading"
    >
      <el-form-item label="所属组别" prop="admin_role_code">
        <!-- <el-input v-model="form.admin_role_code" autocomplete="off"></el-input>
         -->
        <el-select
          style="width: 550px"
          v-model="form.admin_role_code"
          prop="account_type"
        >
          <el-option
            v-for="item in cateData"
            :key="item.id"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户名" prop="user_name">
        <el-input v-model="form.user_name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="nick_name">
        <el-input v-model="form.nick_name" autocomplete="off"></el-input>
      </el-form-item>

      <template v-if="isEdit !== true">
        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="pwd_confirm">
          <el-input v-model="form.pwd_confirm" autocomplete="off"></el-input>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="密码">
          <el-input v-model="password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input v-model="form.pwd_confirm" autocomplete="off"></el-input>
        </el-form-item>
      </template>
      <el-form-item label="是否外部" prop="is_outer" v-if="!isOuter">
        <el-radio v-model="form.is_outer" :label="true">是</el-radio>
        <el-radio v-model="form.is_outer" :label="false">否</el-radio>
      </el-form-item>
      <el-form-item label="是否启用" prop="status">
        <el-radio v-model="form.status" label="normal">正常</el-radio>
        <el-radio v-model="form.status" label="forbidden">禁用</el-radio>
      </el-form-item>
      <el-form-item label="是否超级管理" prop="is_supper">
        <el-switch v-model="form.is_supper"></el-switch>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import API from "@/api";
import local from "@/utils/localStroage.js";

export default {
  name: "addForm",
  data() {
    var checkUserName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入用户名"));
      } else {
        let reg = /[^\w\\]/g;
        if (reg.test(value)) {
          callback(new Error("目前只支持英文，不包括特殊字符"));
        } else {
          callback();
        }
      }
    };
    return {
      formLabelWidth: "100px",
      form: {
        user_name: "",
        admin_role_code: "",
        nick_name: "",
        password: "",
        pwd_confirm: "",
        status: "normal",
        is_outer: true,
        is_supper: true,
      },
      rules: {
        admin_role_code: [
          { required: true, message: "请选择所属组别", trigger: "change" },
        ],
        user_name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
          // { message: "目前只支持英文，不包括特殊字符", trigger: "blur" },
          { validator: checkUserName, trigger: "blur" },
        ],
        nick_name: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        pwd_confirm: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
        ],
      },
      isEdit: false,
      show: false,
      cateData: [],
      password: "******",
      pwd_confirm: "******",
      loading: false,
      isOuter: false,
    };
  },
  methods: {
    submit() {
      const funName = this.isEdit ? "updateData" : "addData";
      if (this.isOuter) {
        this.form.is_outer = undefined;
      }
      this.$refs.form.validate((valid) => {
        valid ? this[funName]() : "";
      });
    },
    updateData() {
      // 编辑如果修改密码--密码字段为omitempty
      // 如果编辑的password不为* 则证明编辑了密码
      if (this.password != "******") {
        this.form.omitempty = this.password;
      }
      API.updateAdminStrators(this.form)
        .then((res) => {
          if (!res.code) {
            this.$message.success("编辑成功");
            this.show = false;
            this.$parent.getLists();
          } else {
            this.$message.error(res.msg);
            this.show = false;
          }
        })
        .catch(() => {
          this.$message.error("编辑失败");
        });
    },
    editData(data) {
      this.getList();
      // this.form = {
      //   // admin_role_code必传，这个接口没通过
      //   id: data.id,
      //   omitempty: "",
      //   user_name: data.user_name,
      //   admin_role_code: data.admin_role_code,
      //   nick_name: data.nick_name,
      //   // password: data.password,
      //   pwd_confirm: data.pwd_confirm,
      //   status: data.status,
      //   is_supper: data.is_supper,
      // };
      this.form = data;
      this.isEdit = true;
      this.show = true;
    },
    clearData() {
      this.form = {
        user_name: "",
        admin_role_code: "",
        nick_name: "",
        password: "",
        pwd_confirm: "",
        status: "normal",
        is_outer: true,
        is_supper: true,
      };
    },
    addFormData() {
      this.isEdit = false;
      this.clearData();
      this.show = true;
      this.getList();
    },
    addData() {
      API.createAdminStrators(this.form)
        .then((res) => {
          if (!res.code) {
            this.$message.success("创建成功");
            this.show = false;
            this.$parent.getLists();
          } else {
            this.$message.error(res.msg);
            this.show = false;
          }
        })
        .catch(() => {
          this.$message.error("创建失败");
        });
    },
    // 获取数据
    getList() {
      this.loading = true;
      let params = {
        page: 1,
        size: 1000,
      };
      API.getRolesList(params)
        .then((res) => {
          if (res.code === 0 && res.data.data) {
            this.cateData = res.data.data;
            console.log("this.cateData ", this.cateData);
            this.loading = false;
          } else if (res.code === 401) {
            this.$message.error("登陆信息过期,请重新登陆!");
            this.loading = false;
            return;
          } else {
            this.cateData = [];
            this.total = 0;
            this.loading = false;
          }
        })
        .catch(() => {
          this.cateData = [];
          this.loading = false;
        });
    },
  },
  created() {
    let userInfo = local.get("userInfo");
    this.isOuter = userInfo.is_outer;
    this.getList();
  },
};
</script>

<style scoped></style>

<template>
  <div
    class="videoList-box"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="top">
      <p class="topTitle">管理员管理</p>
      <div class="topText">
        一个管理员可以有多个角色组,左侧的菜单根据管理员所拥有的权限进行生成
      </div>
    </div>
    <!--操作栏-->
    <!-- <div class="action-bar">
      <el-button type="success" size="small" @click="addFromMethod"
        >添加</el-button
      >
    </div> -->
    <refreshbtn @refesh="refesh">
      <template slot="search-btns">
        <el-button
          type="success"
          size="small"
          v-has="'/api/auth/admin/user/create'"
          @click="addFromMethod"
          >添加</el-button
        >
      </template>
    </refreshbtn>
    <!--数据表格-->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      ref="multipleTable"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      height="580"
      :key="updataSta"
    >
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        width="100"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="user_name"
        label="用户名"
        align="center"
        :show-overflow-tooltip="true"
        width="300"
      >
      </el-table-column>
      <el-table-column prop="nick_name" label="昵称" width="300" align="center">
      </el-table-column>
      <el-table-column
        label="所属组别"
        prop="admin_role_code"
        :formatter="changeData"
        align="center"
        width="280"
      >
      </el-table-column>
      <el-table-column
        label="是否外部"
        v-if="!userInfo.is_outer"
        prop="is_outer"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_outer">是</el-tag>
          <el-tag v-else>否</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status" align="center" width="120">
        <template slot-scope="scope">
          <i
            :class="
              scope.row.status == 'normal'
                ? 'el-icon-circle-check'
                : 'el-icon-circle-close'
            "
            :style="
              scope.row.status == 'normal'
                ? { color: 'green' }
                : { color: 'red' }
            "
          ></i
          >{{ scope.row.status == "normal" ? "正常" : "禁用" }}
        </template>
      </el-table-column>
      <el-table-column label="最后登陆" prop="login_time" align="center">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="160" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            v-has="'/api/auth/admin/user/update'"
            @click="editForm(scope.row)"
          >
            编辑
          </el-button>
          <el-button
            type="text"
            size="small"
            v-has="'/api/auth/admin/user/destroy'"
            @click="deleteForm(scope.row.id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--封面图、视频弹窗组件-->
    <!-- 分页组件 -->
    <div class="bottom">
      <div class="page">
        <el-pagination
          :page-sizes="[10, 25, 50, 100]"
          :current-page="params.page"
          :page-size="params.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          v-if="total > 10"
          @size-change="changeSize"
          @current-change="changeCurrent"
        ></el-pagination>
      </div>
    </div>
    <addManage ref="addForm"></addManage>
  </div>
</template>

<script>
import addManage from "./components/addManage.vue";
import API from "@/api";
import local from "@/utils/localStroage.js";

export default {
  name: "administrators",
  data() {
    return {
      // 请求列表查询
      params: {
        page: 1,
        size: 10,
        product_name: "",
      },
      loading: false,
      // 列表数据
      tableData: [],
      cateData: [],
      total: 0,
      dialogVisible: false,
      updataSta: false,
      isRefesh: true,
      userInfo: undefined,
    };
  },
  components: {
    addManage,
  },
  created() {
    this.userInfo = local.get("userInfo");
    let listRule = "/api/auth/admin/user/list";
    let obj = JSON.parse(
      JSON.parse(JSON.stringify(localStorage.getItem("btnPower")))
    );
    console.log(obj[listRule]);
    if (obj[listRule]) {
      this.isRefesh = true;
      this.getLists();
    } else {
      this.isRefesh = false;
    }
  },
  methods: {
    refesh() {
      this.isRefesh ? this.getLists() : "";
    },
    handleImageUrl(src) {
      if (src[0] === "/") {
        src = src.substr(1, src.length);
      }
      return src;
    },
    addFromMethod() {
      this.$refs.addForm.addFormData();
    },
    // 编辑
    editForm(data) {
      console.log(data, "00000");
      this.$refs.addForm.editData(this._.cloneDeep(data));
    },
    // 删除
    deleteForm(id) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        API.destoryAdminStrators({ id }).then((res) => {
          if (!res.code) {
            this.$message.success("删除成功");
            this.getLists();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 获取数据
    getList() {
      API.getAdminStratorsList(this.params)
        .then((res) => {
          if (res.code === 0 && res.data.data) {
            this.tableData = res.data.data;
            this.tableData.forEach((item) => {
              const timestamp = item.login_time * 1000;
              const date = new Date(timestamp);
              item.login_time = date.toLocaleString();
            });
            this.total = res.data.pagination.count;
            this.loading = false;
          } else if (res.code === 401) {
            this.$message.error("登陆信息过期,请重新登陆!");
            this.loading = false;
            return;
          } else {
            this.tableData = [];
            this.total = 0;
            this.loading = false;
          }
        })
        .catch(() => {
          this.tableData = [];
          this.loading = false;
        });
    },
    // 获取所属组别--用于数据过滤
    // 获取数据
    getLists() {
      let params = {
        page: 1,
        size: 1000,
      };
      this.loading = true;
      API.getRolesList(params)
        .then((res) => {
          if (res.code === 0 && res.data.data) {
            this.cateData = res.data.data;
          }
          this.getList();
        })
        .catch(() => {
          this.cateData = [];
        });
    },

    // 分页条数改变回调
    changeSize(size) {
      this.params.size = size;
      this.getLists();
    },
    // 分页页码改变回调
    changeCurrent(number) {
      this.params.page = number;
      this.getLists();
    },
    // 关闭窗口
    close() {
      this.dialogVisible = false;
    },
    changeData(row) {
      const newArr = this.cateData.filter(
        (item) => item.code == row.admin_role_code
      );
      return newArr.length ? newArr[0].name : "";
    },
  },
};
</script>

<style scoped lang="scss">
.videoList-box {
  min-height: 100vh;
  overflow-y: auto;
  .top {
    padding: 10px;
    width: 100%;
    height: 80px;
    background: #e9edf0;
    margin-bottom: 20px;
    .topText {
      margin-top: 10px;
      font-size: 14px;
      color: #909399;
    }
  }
}

.bottom {
  margin-top: 20px;

  .btn {
    display: inline-block;
    margin-left: 20px;
  }

  .page {
    display: inline-block;
    float: right;
  }
}

.el-pagination {
  margin-top: 0 !important;
}

.dialog-footer {
  left: 0;
}

.tagBox {
  display: inline-block;
  margin-right: 5px;
}
</style>
